import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/Layout';
import { StaticImage } from "gatsby-plugin-image";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="art" mdxType="Layout">
      <h1>{`test page`}</h1>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      